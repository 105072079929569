import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";


const FeatureGrid = ({ gridItems }) => {
  // Declare a const variable within the component
  const imageStyle = {
display: "block",
width: "100%",
height: "12rem",
objectFit: "cover", };
return (
  <div className="columns is-multiline">
    {gridItems.map((item) => {
      const { alt = "", childImageSharp, image } = item;
      
      return (
        <div key={item.text} className="column">
          <div class="card">
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              style={imageStyle}
              alt={alt}
            />
            <div class="cardcontainer">
              <h4><b>{item.heading}</b></h4>
              <p>{item.text}</p>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);
};


FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;


{/* <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: "240px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
        </section> */}