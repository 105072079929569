import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import Speakers from "../components/Speakers";
import Pricing from "../components/Pricing";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  speakers,
  intro,
  fees,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage img={heroImage} />
      <section className="section section--gradient">
        <div className="container ">
          <div className="section">
            <div className="columns">
              <div className="column is-12">
                <div className="content">

                  <div className="blueSec padding_bot">
                    <div className="content">
                      <h1 className="title">{intro.title}</h1>
                    </div>
                    <Features gridItems={intro.blurbs} />
                  </div>

                  <div className="yellowSec padding_bot">
                    <h1 className="title">{speakers.title}</h1>
                    <Speakers gridItems={speakers.blurbs} />
                  </div>

                  <div className="greenSec padding_bot">
                    <h1 className="title">{fees.title}</h1>

                      <Pricing fees={fees} />
                      <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/book">
                        Book Now
                      </Link>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        intro={frontmatter.intro}
        speakers={frontmatter.speakers}
        fees={frontmatter.fees}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        intro {
          title
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
            text
            heading
          }
        }
        speakers{
          title
           blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
            text
            heading
          }
        }
        fees{
          title
          paymentdetails {
            title
            description
            accountname
            bsb
            account
          }
          pricing{
            weekendtitle
            fulltitle
            options{
              title
              weekendprice
              fullprice
            }
          }
        }
      }
    }
  }
`;


          