import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";


const FeatureGrid = ({ gridItems }) => {
  // Declare a const variable within the component
  const imageStyle = {
display: "block",
minWidth: "8rem",
height: "10rem",
objectFit: "cover", };
return (
    gridItems.map((item) => {
      const { alt = "", childImageSharp, image } = item;
      
      return (
        
          
                <tr>
                <td >
                  <p>{item.title}</p>
                </td>
                <td >
                <p>{item.weekendprice}</p>
                </td>
                <td >
                <p>{item.fullprice}</p>
                </td>
                </tr>
      );
    })
);
};


FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;


{/* <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: "240px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
        </section> */}