import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import PricingTable from "./PricingTable.js";


const FeatureGrid = ({ fees }) => {
  // Declare a const variable within the component
  const imageStyle = {
display: "block",
minWidth: "8rem",
height: "10rem",
objectFit: "cover", };
return (
  <div className="columns is-multiline">
  <div className="column">
      <div className="card feescard">
      <div className="cardcontainer ">
        <h4>{fees.paymentdetails.title}</h4>
        <p>{fees.paymentdetails.description}</p>
        <div className="bankDeets">
        <p><b>ACCOUNT NAME:</b> {fees.paymentdetails.accountname}</p>
        <p><b>BSB:</b> {fees.paymentdetails.bsb}</p>
        <p><b>ACCOUNT:</b> {fees.paymentdetails.account}</p>
        </div>
        <div className="fees">
          <table>
          <tr>
          <th >
            <p>Category</p>
          </th>
          <th >
          <p>{fees.pricing.weekendtitle}</p>
          </th>
          <th >
          <p>{fees.pricing.fulltitle}</p>
          </th>
          </tr>
          
          <PricingTable gridItems={fees.pricing.options} />
          </table>
      </div>
      </div>
  </div>
  </div>
  </div>
);
};


FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;


{/* <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: "240px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
        </section> */}